import { useEffect } from "react";

const HomeBgAnimation = () => {
    // wait until DOM has been rendered
    useEffect(() => {
        var movementStrength = 15;
        var height = movementStrength / window.innerHeight;
        var width = movementStrength / window.innerWidth;

        document.querySelector(".home").addEventListener("mousemove", function (e) {
            var pageX = e.pageX - (window.innerWidth / 2);
            var pageY = e.pageY - (window.innerHeight / 2);
            var newvalueX = width * pageX * -1;
            var newvalueY = height * pageY * -1;

            var s = document.querySelector(".home-photo .hp-inner:not(.without-move)");

            s.classList.add("transition");
            s.style.backgroundPosition = "calc( 50% + " + newvalueX + "px ) calc( 50% + " + newvalueY + "px )";

            setTimeout(() => {
                s.classList.remove("transition")
            }, 300);

        });

        var TxtRotate = function (el, toRotate, period) {
            this.toRotate = toRotate;
            this.el = el;
            this.loopNum = 0;
            this.period = parseInt(period, 10) || 1000;
            this.txt = "";
            this.tick();
            this.isDeleting = false;
        };

        TxtRotate.prototype.tick = function () {
            var i = this.loopNum % this.toRotate.length;
            var fullTxt = this.toRotate[i];

            if (this.isDeleting) {
                this.txt = fullTxt.substring(0, this.txt.length - 1);
            } else {
                this.txt = fullTxt.substring(0, this.txt.length + 1);
            }

            this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

            var that = this;
            var delta = 300 - Math.random() * 100;

            if (this.isDeleting) {
                delta /= 2;
            }

            if (!this.isDeleting && this.txt === fullTxt) {
                delta = this.period;
                this.isDeleting = true;
            } else if (this.isDeleting && this.txt === "") {
                this.isDeleting = false;
                this.loopNum++;
                delta = 500;
            }

            setTimeout(function () {
                that.tick();
            }, delta);
        };

        const textFun  = function () {
            var elements = document.getElementsByClassName("txt-rotate");
            for (var i = 0; i < elements.length; i++) {
                var toRotate = elements[i].getAttribute("data-rotate");
                var period = elements[i].getAttribute("data-period");
                if (toRotate) {
                    new TxtRotate(elements[i], JSON.parse(toRotate), period);
                }
            }
            // INJECT CSS
            var css = document.createElement("style");
            css.type = "text/css";
            css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
            document.body.appendChild(css);
        };
        textFun();
    });
}

export default HomeBgAnimation